import { EMPTY_CHAR_SYMBOL } from '@/constants'
import {
    computePaymentOrderCurrency,
    computePaymentPropertiesByServiceName,
    generatePaymentOrderDefaultDirectDebitMandate,
    isFinancialInstitutionConnectedAccount
} from '@/services'
import {
    ConnectedAccount,
    CounterpartyAccount,
    CounterpartyAccountStatusSchema,
    CurrencyCode,
    PaymentOrder,
    ServiceName
} from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

export function computePartialPaymentOrderFormFields(account?: ConnectedAccount): Partial<PaymentOrder> {
    const item: Partial<PaymentOrder> = Object.create(null)
    const serviceName = chain(account).get('services_activated').head().value()
    const { type, direction } = computePaymentPropertiesByServiceName(serviceName as ServiceName)
    const currency: CurrencyCode = computePaymentOrderCurrency(type)
    const directDebitMandate = generatePaymentOrderDefaultDirectDebitMandate(type, direction)
    const originatingAccount = isFinancialInstitutionConnectedAccount(account)
        ? { account_number: EMPTY_CHAR_SYMBOL, holder_name: EMPTY_CHAR_SYMBOL, bank_code: account?.bank_code }
        : undefined

    item.type = type
    item.direction = direction
    item.currency = currency
    item.connected_account_id = account?.id
    item.direct_debit_mandate = directDebitMandate
    item.originating_account = originatingAccount

    return item
}

export function approvedCounterpartyAccountsPaymentOrderFormFilter(value?: CounterpartyAccount): boolean {
    return value?.status === CounterpartyAccountStatusSchema.Enum.approved
}

export function getPaymentOrderFormInitialValues(): Partial<PaymentOrder> {
    const item: Partial<PaymentOrder> = Object.create(null)

    item.connected_account_id = undefined
    item.type = undefined
    item.direction = undefined
    item.currency = undefined
    item.direct_debit_mandate = undefined
    item.originating_account = undefined
    item.receiving_account_id = undefined
    item.receiving_account = undefined
    item.amount = undefined
    item.reference = undefined
    item.auto_approval = false

    // Optionals
    item.fee_option = undefined
    item.treasury_option = undefined
    item.confidentiality_option = undefined

    return item
}
