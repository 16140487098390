import { corporateServicesConnectedAccountsFilter } from '@/services'
import { PaymentOrderType, PaymentOrderTypeSchema, ServiceName, ServiceNameSchema } from '@webapps/numeral-ui-core'
import { chain } from 'lodash'

/**
 * @todo
 * Move and evolve the API of this component to correctly map the services to custom payment order types.
 */
export function getAvailablePaymentOrderTypesFromConnectedAccountServiceNames(
    serviceNames?: ServiceName[]
): Partial<(PaymentOrderType & ServiceName)[]> {
    if (!serviceNames?.length) {
        return []
    }

    return chain(serviceNames)
        .filter(corporateServicesConnectedAccountsFilter)
        .reduce((acc, item, index) => {
            if (item?.includes(PaymentOrderTypeSchema.Enum.treasury)) {
                // @ts-ignore TBD
                acc[index] = ServiceNameSchema.Enum.treasury_local
            } else {
                // @ts-ignore TBD
                acc[index] = item
            }
            return acc
        }, [])
        .uniq()
        .value()
}
