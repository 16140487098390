import { ApiObjectTypeSchema, Inquiry, ReturnRequestStatus, ReturnRequestStatusSchema } from '@webapps/numeral-ui-core'

export function getInquiryStatusMapper(data: Partial<Inquiry>): ReturnRequestStatus {
    const isDenied = !!data?.denied_at
    return isDenied ? ReturnRequestStatusSchema.Values.denied : ReturnRequestStatusSchema.Values.received
}

export function canDenyInquiry(data?: Inquiry): boolean {
    const isRelatedRequestTypeReturnRequest = data?.related_request_type === ApiObjectTypeSchema.Values.return_request
    const isReceived = getInquiryStatusMapper(data as Inquiry) === ReturnRequestStatusSchema.Values.received

    return isRelatedRequestTypeReturnRequest && isReceived
}
