import { Form, FormPageFooter, FormSection } from '@/components'
import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { transformCustomFieldNameToSnakeCase } from '@/services'
import { CustomFieldKeyValidator } from '@/utils/@validators'
import { CustomField, CustomFieldTypesSchema } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { ChangeEvent, useMemo } from 'react'
import {
    CustomFieldFormKeyTooltip,
    CustomFieldFormStickyFooter,
    CustomFieldObjectTypesInput,
    CustomFieldTypeInput,
    CustomFieldValuesInput
} from './@components'
import { CustomFieldFormProps } from './CustomField.form.types'
import { getCustomFieldFormInitialValues } from './CustomField.form.utils'

export const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
    data,
    disabled,
    loading,
    submitLabelMessageId,
    onSubmit = noop
}) => {
    const initialValues = useMemo(() => getCustomFieldFormInitialValues(data), [data])
    const isEditMode = useMemo(() => globalThis.Boolean(data?.id), [data])

    return (
        <Form<Partial<CustomField>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, handleSubmit, dirty }) => {
                const onChangeCustomFieldName = (event: ChangeEvent<HTMLInputElement>) => {
                    const nameValue = event.target.value

                    setFieldValue(`name`, nameValue)

                    if (!isEditMode) {
                        setFieldValue(`key`, transformCustomFieldNameToSnakeCase(nameValue))
                    }
                }

                return (
                    <>
                        <TextInput
                            name="name"
                            customPlaceholderKey="app.settings.custom_fields.form.name.placeholder"
                            onChange={onChangeCustomFieldName}
                            isRequired={true}
                        />
                        <TextInput
                            name="key"
                            customPlaceholderKey="app.settings.custom_fields.form.key.placeholder"
                            labelRightElement={<CustomFieldFormKeyTooltip />}
                            isDisabled={isEditMode}
                            validator={CustomFieldKeyValidator}
                            isRequired={true}
                        />
                        <CustomFieldObjectTypesInput
                            name="object_types"
                            customLabelKey="app.page.details.fields.object_types.label"
                            isRequired={true}
                        />
                        <CustomFieldTypeInput name="type" value={values.type} isRequired={!isEditMode} />
                        <If condition={values.type === CustomFieldTypesSchema.Enum.select}>
                            <FormSection titleId="app.settings.custom_fields.form.sections.values.title">
                                <CustomFieldValuesInput name="values" value={data?.values} isRequired={true} />
                            </FormSection>
                        </If>
                        <CustomFieldFormStickyFooter>
                            <FormPageFooter
                                onSubmit={handleSubmit}
                                isDisabled={disabled || !dirty}
                                submitLabelMessageId={submitLabelMessageId}
                                isLoading={loading}
                                showUnsavedChangesWarning={true}
                                margin="unset"
                            />
                        </CustomFieldFormStickyFooter>
                    </>
                )
            }}
        </Form>
    )
}
