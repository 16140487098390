import { DetailPageLink, If, Separator } from '@/components/@misc'
import { Text } from '@chakra-ui/react'
import { ApiObjectTypeSchema, PaymentOrder, PaymentRetryRule, SepaReturnReason, Uuid } from '@webapps/numeral-ui-core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { RetriedPaymentSummary } from './PaymentOrderRetries.page.type'

/**
 * Original payment order is the initial payment order in a chain of retries
 * @param paymentOrder
 * @returns true if the payment has a retry rule id but no original payment id
 */
export function isOriginalPaymentOrder(paymentOrder?: PaymentOrder): boolean {
    return (
        !!paymentOrder &&
        !!paymentOrder.retry_details?.payment_retry_rule_id &&
        !paymentOrder?.retry_details?.original_payment_id
    )
}

export function getOriginalPaymentOrderId(paymentOrder?: PaymentOrder): Uuid | null {
    switch (true) {
        case !paymentOrder || !paymentOrder.retry_details?.payment_retry_rule_id:
            return null
        case isOriginalPaymentOrder(paymentOrder):
            return paymentOrder.id
        default:
            return paymentOrder.retry_details?.original_payment_id
    }
}

function sortByCreationDate(paymentA: PaymentOrder, paymentB: PaymentOrder) {
    const dateA = new Date(paymentA.created_at)
    const dateB = new Date(paymentB.created_at)
    return dateA.getTime() - dateB.getTime()
}

/**
 * Create an object interesecting data from the automatically retried payments and the retry rule used
 * @param originalRetriedPayment The original retried payment
 * @param nonOriginalRetriedPayments All following retried payments
 * @param retryRule The retry rule used to automatically retry the payments
 */
export function getPaymentOrderRetriesSummary(
    originalRetriedPayment?: PaymentOrder,
    nonOriginalRetriedPayments?: PaymentOrder[],
    retryRule?: PaymentRetryRule
): RetriedPaymentSummary[] {
    if (!originalRetriedPayment || !retryRule) {
        return []
    }

    const sortedNonOriginalRetriedPayments = nonOriginalRetriedPayments?.sort(sortByCreationDate)

    const originalPaymentOrderRetrySummary: RetriedPaymentSummary = {
        retried_payment_id: originalRetriedPayment?.id,
        retried_payment_status: originalRetriedPayment?.status,
        retried_payment_return_reason_code: originalRetriedPayment?.status_details as SepaReturnReason,
        retried_payment_date: originalRetriedPayment?.created_at,
        retried_payment_type: originalRetriedPayment?.type,
        retried_payment_direction: originalRetriedPayment?.direction,
        payment_retry_rule_id: retryRule?.id
    }

    const nonOriginalPaymentOrderRetriesSummary: RetriedPaymentSummary[] = retryRule.retries.map((retry, index) => {
        // Get the payment order potentially created from current retry rule
        // undefined is expected if this retry has not happened
        const retriedPayment: PaymentOrder | undefined = sortedNonOriginalRetriedPayments?.[index]
        return {
            retried_payment_id: retriedPayment?.id,
            retried_payment_status: retriedPayment?.status,
            retried_payment_return_reason_code: retriedPayment?.status_details as SepaReturnReason,
            retried_payment_date: retriedPayment?.created_at,
            retried_payment_direction: retryRule?.retry_conditions.payment_direction,
            payment_retry_rule_id: retryRule?.id,
            retried_payment_type: retry?.retry_to_payment_type,
            retried_after: retry?.retry_after
        }
    })

    return [originalPaymentOrderRetrySummary, ...nonOriginalPaymentOrderRetriesSummary]
}

export function getPaymentRetrySummaryTitle(
    paymentRetrySummary?: RetriedPaymentSummary,
    paymentRetryIndex?: number,
    originalPaymentId?: Uuid | null,
    isSelf?: boolean
) {
    if (!paymentRetrySummary || !originalPaymentId) {
        return
    }

    let title: ReactNode
    if (paymentRetrySummary.retried_payment_id === originalPaymentId) {
        title = <FormattedMessage id="app.payments.payment_orders.tabs.retries.retry_summary.original.title" />
    } else {
        title = (
            <FormattedMessage
                id="app.payments.payment_orders.tabs.retries.retry_summary.title"
                values={{ retryNumber: paymentRetryIndex }}
            />
        )
    }

    let titleSuffix: ReactNode
    if (isSelf) {
        titleSuffix = <FormattedMessage id="app.payments.payment_orders.tabs.retries.retry_summary.self.title_suffix" />
    } else {
        titleSuffix = (
            <DetailPageLink
                fontWeight="inherit"
                customLabelMessageKey="app.payments.payment_orders.tabs.retries.retry_summary.title_suffix_link.label"
                objectId={paymentRetrySummary.retried_payment_id}
                objectType={ApiObjectTypeSchema.Enum.payment_order}
            />
        )
    }

    return (
        <Text as="span" fontWeight="500">
            {title}

            <If condition={!!paymentRetrySummary.retried_payment_id}>
                <Separator />
                {titleSuffix}
            </If>
        </Text>
    )
}
