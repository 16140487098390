import {
    DirectDebitMandate,
    DirectDebitMandateDirectionSchema,
    DirectDebitMandateStatusSchema
} from '@webapps/numeral-ui-core'

export function showAuthorizeMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.Values.incoming &&
        mandate.status === DirectDebitMandateStatusSchema.Values.blocked
    )
}

export function showDisableMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.Values.outgoing &&
        mandate.status === DirectDebitMandateStatusSchema.Values.active
    )
}

export function showBlockMandateAction(mandate: DirectDebitMandate) {
    return (
        mandate.direction === DirectDebitMandateDirectionSchema.Values.incoming &&
        mandate.status === DirectDebitMandateStatusSchema.Values.active
    )
}
