import { FilesIcon } from '@/components/@icons'
import { ParentPageLayout } from '@/layouts'
import {
    AuditTrailFilterByOption,
    AuditTrailPage,
    EventsPage,
    FileDetailsHeader,
    FileDetailsPage,
    FilesPage
} from '@/pages'
import { NavigationRoute, getNestedRoutesWithTabLayout } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS } from '../NavigationRoutesProvider.const'

export function getFilesNavigationRoutes(): NavigationRoute {
    const { UUID, FILES } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const filesRoutes = [
        {
            title: 'app.files.tabs.details.title',
            path: FILES.DETAILS,
            element: <FileDetailsPage />
        },
        {
            title: 'app.files.tabs.events.title',
            path: FILES.EVENTS,
            element: <EventsPage />
        },
        {
            title: 'app.files.tabs.audit_trail.title',
            path: FILES.AUDIT_TRAIL,
            element: (
                <AuditTrailPage
                    objectType={ApiObjectTypeExtendedSchema.Values.file}
                    filterBy={AuditTrailFilterByOption.OBJECT}
                />
            )
        }
    ]

    return {
        title: 'app.files.title',
        path: '/files',
        icon: <FilesIcon />,
        element: <ParentPageLayout element={<FilesPage />} />,
        routes: getNestedRoutesWithTabLayout(UUID, filesRoutes, {
            header: <FileDetailsHeader />
        })
    }
}
