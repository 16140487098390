import { ReconciliationStatus, ReconciliationStatusSchema } from '@webapps/numeral-ui-core'

export const computeReconciliationStatusColor = (status?: ReconciliationStatus) => {
    switch (status) {
        case ReconciliationStatusSchema.Values.partially_reconciled: {
            return 'orange.200'
        }
        case ReconciliationStatusSchema.Values.reconciled: {
            return 'green.300'
        }
        case ReconciliationStatusSchema.Values.unreconciled: {
            return 'red.500'
        }

        case ReconciliationStatusSchema.Values.excluded:
        default: {
            return 'gray.300'
        }
    }
}
