import {
    FilterInputs,
    TableCellShortDate,
    TableCellStatus,
    TableCellTextIntl,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    ConnectedAccountsInput,
    DateInput,
    PaymentOrderTypeInput,
    ReturnRequestRelatedPaymentTypeInput,
    ReturnRequestStatusInput,
    TextInput
} from '@/components/@inputs'
import { DetailPageLink } from '@/components/@misc'
import { COLUMN_WIDTH_RATIO, DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { uuidValidator } from '@/utils/@validators'
import {
    ReturnRequest,
    ReturnRequestsServiceFindAllQueryOptions,
    ReturnRequestStatusSchema
} from '@webapps/numeral-ui-core'
import { ReturnRequestsCustomColumns } from './ReturnRequests.page.types'
import { QueryParamQuickFilters } from '@/hooks'

export const RETURN_REQUEST_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/the-return-request-object`

export const RETURN_REQUEST_TABLE_STATIC_STATE: TableStateWithEntity<ReturnRequest, ReturnRequestsCustomColumns> = {
    columnOrder: Object.freeze(['created_at', 'direction', 'return_reason', 'related_objects', 'status'])
}

export const RETURN_REQUEST_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<
    ReturnRequest,
    ReturnRequestsCustomColumns
> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        cell(info) {
            const { related_payment_type } = info.row.original

            return <TableCellTextIntl messageId={`app.payments.direction.${related_payment_type}.label`} />
        }
    },
    related_objects: {
        cell(info) {
            const { related_payment_type, related_payment_id } = info.row.original
            return <DetailPageLink objectId={related_payment_id} objectType={related_payment_type} />
        }
    },
    status: {
        minSize: MIN_COLUMN_WIDTH.STATUS,
        cell(info) {
            return <TableCellStatus value={info.getValue()} />
        }
    }
}

export const RETURN_REQUEST_FILTERS: FilterInputs<ReturnRequestsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    start_value_date: <DateInput name="start_value_date" />,
    end_value_date: <DateInput name="end_value_date" />,
    type: <PaymentOrderTypeInput name="type" />,
    status: <ReturnRequestStatusInput name="status" />,
    related_payment_type: <ReturnRequestRelatedPaymentTypeInput name="related_payment_type" />,
    related_payment_id: <TextInput name="related_payment_id" validator={uuidValidator} />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />
}

export const RETURN_REQUEST_QUICK_FILTERS: QueryParamQuickFilters<ReturnRequestsServiceFindAllQueryOptions> = {
    status: [
        ReturnRequestStatusSchema.Enum.received,
        ReturnRequestStatusSchema.Enum.sent,
        ReturnRequestStatusSchema.Enum.denied
    ]
}
