import { Payment } from '@/types'
import {
    Amount,
    PaymentTypeSchema,
    ReconciliationStatus,
    ReconciliationStatusSchema,
    Transaction
} from '@webapps/numeral-ui-core'
import { chain } from 'lodash'
import { SourceReconciliationObject, TargetReconciliationObject } from './Reconciliations.service.types'

/**
 * @description
 * Helper, asserts if the source and target objects can be reconciled.
 */
function areEntitiesReconcilable<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObject?: TargetReconciliationObject<T>
): void | Error {
    const { assert } = globalThis.console
    const hasIdentities = sourceObject && targetObject
    const isOfSameType = sourceObject?.object === targetObject?.object
    const isSameObject = globalThis.Object.is(sourceObject, targetObject)

    if (!hasIdentities) {
        return
    }

    assert(!(isOfSameType || isSameObject), 'Cannot compute a reconcilable amount between the same object')
}

export function isEntityReconciled(entity?: Payment | Transaction): boolean {
    return entity?.reconciliation_status === ReconciliationStatusSchema.Values.reconciled
}

export function isEntityUnreconciled(entity?: Payment | Transaction): boolean {
    return entity?.reconciliation_status === ReconciliationStatusSchema.Values.unreconciled
}

export function isReconcilablePaymentObjectType(entity?: Payment | Transaction): boolean {
    switch (entity?.object) {
        case PaymentTypeSchema.Values.payment_order:
        case PaymentTypeSchema.Values.expected_payment:
        case PaymentTypeSchema.Values.incoming_payment:
        case PaymentTypeSchema.Values.return:
        case PaymentTypeSchema.Values.payment_capture: {
            return true
        }
    }

    return false
}

/**
 * @description
 * Helper, checks for exact specific statuses to enable a reconciliation to take place.
 */
export function isCreateReconciliationAvailable(reconciliationStatus?: ReconciliationStatus): boolean {
    switch (reconciliationStatus) {
        case ReconciliationStatusSchema.Values.unreconciled:
        case ReconciliationStatusSchema.Values.partially_reconciled: {
            return true
        }

        default: {
            return false
        }
    }
}

/**
 * @description
 * Helper, get the reconcilable amount of an entity:
 * Represented as the difference between `amount` and already the `reconciled_amount` for each given entity type.
 */
export function getReconcilableAmount(entity?: Payment | Transaction): Amount {
    if (!entity) {
        return 0
    }

    const entityWrapper = chain<typeof entity>(entity)
    const reconciledAmount: Amount = getReconciledAmount(entity)
    let remainingAmount: Amount

    switch (entity.object) {
        case PaymentTypeSchema.Values.expected_payment: {
            const amountFrom = entityWrapper.get('amount_from', 0).value()
            const amountTo = entityWrapper.get('amount_to', 0).value()
            const maxAmount = chain<Amount>([amountFrom, amountTo]).max().value()

            remainingAmount = globalThis.Math.max(chain<Amount>(maxAmount).subtract(reconciledAmount).value(), 0)
            break
        }

        case PaymentTypeSchema.Values.return: {
            remainingAmount = entityWrapper.get('returned_amount', 0).subtract(reconciledAmount).value()
            break
        }

        default: {
            remainingAmount = entityWrapper.get('amount', 0).subtract(reconciledAmount).value()
            break
        }
    }

    return globalThis.Math.abs(remainingAmount)
}

/**
 * @description
 * Helper, gets the `amount` for each entity type.
 */
export function getAmountByEntityType(entity?: Payment | Transaction): Amount {
    if (!entity) {
        return 0
    }

    const entityWrapper = chain(entity)
    let amount: Amount = 0

    switch (entity?.object) {
        case PaymentTypeSchema.Values.expected_payment: {
            amount = entityWrapper.get('amount_to', 0).value()
            break
        }

        case PaymentTypeSchema.Values.return: {
            amount = entityWrapper.get('returned_amount', 0).value()
            break
        }

        default: {
            amount = entityWrapper.get('amount', 0).value()
            break
        }
    }

    return amount
}

/**
 * @description
 * Helper, that scans a set of entities for matching `currency` field values.
 */
export function areReconcilingEntitiesMatchingCurrencies(...entities: Payment[] | Transaction[] | any[]): boolean {
    const currency = entities?.at(0)?.currency

    if (!currency) {
        return false
    }

    return entities.filter(globalThis.Boolean).every((value) => value?.currency === currency)
}

/**
 * @description
 * Helper, retrieves the `reconciled_amount` field of a given entity.
 */
export function getReconciledAmount(entity?: Payment | Transaction): Amount {
    return chain(entity).get('reconciled_amount', 0).value()
}

/**
 * @description
 * Computes the amount that can be reconciled between source and target.
 */
export function computeReconcilableAmount<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObject?: TargetReconciliationObject<T>
): Amount {
    const sourceReconcilableAmount = getReconcilableAmount(sourceObject)
    const targetReconcilableAmount = getReconcilableAmount(targetObject)

    areEntitiesReconcilable(sourceObject, targetObject)

    return globalThis.Math.min(sourceReconcilableAmount, targetReconcilableAmount)
}

/**
 * @description
 * Computes the total remaining amount in a "one to many" scenario.
 */
export function computeTotalRemainingReconciliationAmount<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObjects?: TargetReconciliationObject<T>[]
): Amount {
    const sourceAmount: Amount = getReconcilableAmount(sourceObject)
    const totalReconcilableAmount: Amount =
        targetObjects?.reduce((acc, item) => {
            acc += computeReconcilableAmount(sourceObject, item)
            return acc
        }, 0) || 0

    return globalThis.Math.abs(sourceAmount - totalReconcilableAmount)
}

/**
 * @description
 * Computes the total reconcilable amount in a "one to many" scenario.
 */
export function computeTotalReconcilableAmount<T extends Transaction | Payment>(
    sourceObject?: SourceReconciliationObject<T>,
    targetObjects?: TargetReconciliationObject<T>[]
): Amount {
    const sourceAmount: Amount = getReconcilableAmount(sourceObject)
    const totalRemainingReconciliationAmount: Amount = computeTotalRemainingReconciliationAmount(
        sourceObject,
        targetObjects
    )

    return globalThis.Math.abs(sourceAmount - totalRemainingReconciliationAmount)
}
