import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, usePermissions, useQueryFindAllWebhooks } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { PERMISSION, Webhook } from '@/services'
import { queryDataAggregation } from '@/utils'
import { Link } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import {
    WEBHOOKS_DOCUMENTATION_LINK,
    WEBHOOKS_TABLE_COLUMN_CUSTOMIZERS,
    WEBHOOKS_TABLE_STATIC_STATE
} from './Webhooks.page.const'

export const WebhooksPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { hasPermission } = usePermissions()
    const { relativePaths } = useNavigationRoutes()
    const onNavigateToNew = useCallback(() => {
        navigate(relativePaths.NEW)
    }, [navigate, relativePaths])
    const query = useQueryFindAllWebhooks()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (isDataEmpty) {
            const learnMoreLink = (
                <Link href={WEBHOOKS_DOCUMENTATION_LINK} target="_blank">
                    <FormattedMessage id="app.common.link.learn_more.label.alternative" />
                </Link>
            )
            return {
                title: intl.formatMessage({ id: 'app.developers.webhooks.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.developers.webhooks.empty_content.subtitle' }, { learnMoreLink })
            }
        }
    }, [intl, query])
    const onTableRowClick = useTableRowNavigation<Webhook>('id')
    const actionsProps = hasPermission(PERMISSION.DEVELOPERS_CONFIGURE_WEBHOOKS)
        ? [
              {
                  onClick: onNavigateToNew,
                  children: intl.formatMessage({
                      id: 'app.developers.webhooks.add'
                  })
              }
          ]
        : undefined

    return (
        <PageLayout className="Webhooks">
            <RestrictedSection permission={PERMISSION.DEVELOPERS_VIEW_WEBHOOKS}>
                <TableHeader<Webhook> objectType={ApiObjectTypeExtendedSchema.Enum.webhook} actions={actionsProps} />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError || isDataEmpty: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<Webhook>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    state={WEBHOOKS_TABLE_STATIC_STATE}
                                    columnCustomizers={WEBHOOKS_TABLE_COLUMN_CUSTOMIZERS}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
