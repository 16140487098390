import { DetailPageLink, LinkWithFilterBy } from '@/components/@misc'
import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex } from '@chakra-ui/react'
import { ApiObjectTypeSchema, NumeralFile } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo, useRef } from 'react'
import { FileDetailsRelatedObjectsFileIdFilter } from './FileDetailsRelatedObjects.types'
import {
    isUploadedPaymentFile,
    relatedObjectsLabelFromPathReducer,
    relatedObjectsPathFromFileCategoryReducer
} from './FileDetailsRelatedObjects.utils'

interface FileDetailsRelatedObjectsProps {
    file: NumeralFile
}

export const FileDetailsRelatedObjects: React.FC<FileDetailsRelatedObjectsProps> = ({ file }) => {
    const paths = useMemo<string[]>(
        () => relatedObjectsPathFromFileCategoryReducer(file.category, file.direction),
        [file]
    )
    const filterBy = useMemo<FileDetailsRelatedObjectsFileIdFilter>(
        () => ({
            file_id: file.id
        }),
        [file]
    )

    const isLinkToPaymentFileDisplayed = isUploadedPaymentFile(file)
    const hasEmptyPaths = useRef<boolean>(isEmpty(paths)).current

    if (isLinkToPaymentFileDisplayed) {
        return (
            <DetailPageLink
                customLabelMessageKey="app.common.link.payment_file.label"
                objectId={file.id}
                objectType={ApiObjectTypeSchema.Enum.payment_file}
            />
        )
    }

    if (hasEmptyPaths) {
        return <>{EMPTY_VALUE_PLACEHOLDER}</>
    }

    return (
        <Flex className="EntityDetailFileRelatedObjects" flexDirection="column" gap="8px">
            {paths?.map((path) => {
                const label = relatedObjectsLabelFromPathReducer(path)

                return (
                    <LinkWithFilterBy<FileDetailsRelatedObjectsFileIdFilter>
                        labelKey={label}
                        path={path}
                        filterBy={filterBy}
                        key={path}
                    />
                )
            })}
        </Flex>
    )
}
