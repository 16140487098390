import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useQueryFindAllCustomFields } from '@/hooks/api'
import { PageLayout } from '@/layouts'
import { PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { CUSTOM_FIELDS_TABLE_COLUMN_CUSTOMIZERS, CUSTOM_FIELDS_TABLE_STATIC_STATE } from './CustomFields.page.const'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { useNavigationRoutes, usePermissions } from '@/hooks'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { CustomField } from '@webapps/numeral-ui-core'

export const CustomFieldsPage: React.FC = () => {
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const query = useQueryFindAllCustomFields()
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps>(() => {
        return {
            title: intl.formatMessage({ id: 'app.settings.custom_fields.empty_content.title' }),
            content: intl.formatMessage({ id: 'app.settings.custom_fields.empty_content.subtitle' })
        }
    }, [intl])

    const onTableRowClick = useTableRowNavigation<CustomField>('id')
    const actions = useMemo(() => {
        if (!hasPermission(PERMISSION.SETTINGS_CREATE_CUSTOM_FIELDS)) {
            return
        }

        return [
            {
                onClick() {
                    navigate(relativePaths.NEW)
                },
                children: intl.formatMessage({ id: 'app.settings.custom_fields.add' })
            }
        ]
    }, [navigate, relativePaths, intl, hasPermission])

    return (
        <PageLayout className="CustomFields">
            <RestrictedSection permission={PERMISSION.SETTINGS_VIEW_CUSTOM_FIELDS}>
                <TableHeader
                    objectType={ApiObjectTypeExtendedSchema.Enum.custom_field}
                    isLoading={query.isLoading}
                    actions={actions}
                />
                {(() => {
                    switch (true) {
                        case query.isLoading: {
                            return <LoadingSpinner />
                        }

                        case query.isError: {
                            return <EmptyContent />
                        }

                        case isDataEmpty: {
                            return <EmptyContent {...emptyContentProps} />
                        }

                        default: {
                            return (
                                <Table<CustomField>
                                    data={data}
                                    onRowClick={onTableRowClick}
                                    onScrollToBottom={query.fetchNextPage}
                                    isLoading={query.hasNextPage}
                                    columnCustomizers={CUSTOM_FIELDS_TABLE_COLUMN_CUSTOMIZERS}
                                    state={CUSTOM_FIELDS_TABLE_STATIC_STATE}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
