import { EmptyContent, LoadingSpinner, RestrictedSection } from '@/components'
import {
    useMutationDenyCounterpartyAccount,
    useNavigationRoutes,
    useQueryFindByIdCounterpartyAccount,
    useToasts
} from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { isEmpty } from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { CounterpartyAccountDenyForm } from './@components'
import { useMemo } from 'react'
import { CounterpartyAccountStatus, CounterpartyAccountStatusSchema } from '@webapps/numeral-ui-core'
import { FormattedMessage, useIntl } from 'react-intl'
import { TOAST_VARIANTS, updateUUIDRelativeActionPathname } from '@/providers'

export function canAcceptOrDenyCounterpartyAccountByStatus(status?: CounterpartyAccountStatus) {
    return status === CounterpartyAccountStatusSchema.Values.pending_approval
}

export const CounterpartyAccountDenyPage: React.FC = () => {
    const { uuid } = useParams()
    const intl = useIntl()
    const navigate = useNavigate()
    const location = useLocation()
    const { relativePaths } = useNavigationRoutes()

    const title = useMemo<string>(
        () => intl.formatMessage({ id: 'app.counterparties.counterparty_accounts.deny.title' }),
        [intl]
    )
    const { onAdd } = useToasts()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const mutation = useMutationDenyCounterpartyAccount(uuid, {
        onSuccess(response) {
            const { COUNTERPARTIES } = relativePaths
            const path = updateUUIDRelativeActionPathname(location.pathname, COUNTERPARTIES.DETAILS)

            navigate(path)

            onAdd({
                variant: TOAST_VARIANTS.SUCCESS,
                status: 'success',
                title: <FormattedMessage id="app.counterparties.counterparty_accounts.deny.success.toast.title" />,
                description: (
                    <FormattedMessage id="app.counterparties.counterparty_accounts.deny.success.toast.description" />
                )
            })
        }
    })

    const isDisabled = useMemo<boolean>(() => {
        const canAcceptOrDeny = canAcceptOrDenyCounterpartyAccountByStatus(query.data?.status)
        const isLoading = query.isLoading || mutation.isPending
        return !canAcceptOrDeny || isLoading
    }, [query, mutation])

    return (
        <PageLayout className="CounterpartyAccountDeny">
            <PageHeaderLayout title={title} />
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY}
                permission={PERMISSION.COUNTERPARTIES_APPROVE_COUNTERPARTY_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <CounterpartyAccountDenyForm
                                    onSubmit={mutation.mutate}
                                    loading={mutation.isPending}
                                    disabled={isDisabled}
                                />
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
