import { useParams } from 'react-router-dom'
import { useQueryFindByIdReturn } from '@/hooks'
import { PaymentTypeSchema, Return } from '@webapps/numeral-ui-core'
import { ReconciliationsPage } from '@/pages'

export const ReturnsReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdReturn(uuid)

    return <ReconciliationsPage<Return> paymentType={PaymentTypeSchema.Values.return} queryItem={query} />
}
