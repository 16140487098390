import { Payment } from '@/types'
import { getDineroCurrency } from '@/utils'
import { AlertProps } from '@chakra-ui/react'
import {
    Amount,
    CurrencyCode,
    ReconciliationStatus,
    ReconciliationStatusSchema,
    Transaction,
    Uuid
} from '@webapps/numeral-ui-core'
import { add, Currency, dinero, Dinero, equal, lessThan } from 'dinero.js'
import { chain } from 'lodash'
import { IntlShape } from 'react-intl'

export function computeTotalReconciliationItemsAmounts(
    amounts: Record<Uuid, Amount>,
    currencyCode?: CurrencyCode
): Dinero<Amount> | undefined {
    if (!currencyCode) {
        return
    }

    const dineroCurrency = getDineroCurrency(currencyCode) as Currency<number>
    const zeroAmountDineroWrapper = dinero({ amount: 0, currency: dineroCurrency })
    const amountMapper = (amount: Amount) => {
        return dinero({
            amount: amount,
            currency: dineroCurrency
        })
    }

    return chain<typeof amounts>(amounts).values().map(amountMapper).push(zeroAmountDineroWrapper).reduce(add).value()
}

export function computeReconciliationComputedStatusAlertProps(
    remainingAmount: number,
    item?: Payment | Transaction
): (Partial<AlertProps> & { title: string; description: string }) | void {
    if (!item) {
        return
    }

    const dineroCurrency = getDineroCurrency(item?.currency) as Currency<Amount>
    const zeroAmountDineroWrapper = dinero({ amount: 0, currency: dineroCurrency })
    const remainingAmountDineroWrapper = dinero({
        amount: remainingAmount,
        currency: dineroCurrency
    })
    const totalAmountDineroWrapper = dinero({
        amount: chain<typeof item>(item).get('amount', 0).value(),
        currency: dineroCurrency
    })

    switch (true) {
        case equal(remainingAmountDineroWrapper, zeroAmountDineroWrapper): {
            return {
                status: 'success',
                variant: 'numeralSuccess',
                title: 'app.reconciliations.status.reconciled.title',
                description: 'app.reconciliations.status.reconciled.description'
            }
        }

        case lessThan(remainingAmountDineroWrapper, totalAmountDineroWrapper):
        default: {
            return {
                status: 'info',
                variant: 'numeralAccent',
                title: 'app.reconciliations.status.partially_reconciled.title',
                description: 'app.reconciliations.status.partially_reconciled.description'
            }
        }
    }
}

export function isReconciliationStatusVisible(reconciliationStatus?: ReconciliationStatus): boolean {
    switch (reconciliationStatus) {
        case ReconciliationStatusSchema.Values.reconciled:
        case ReconciliationStatusSchema.Values.partially_reconciled: {
            return true
        }

        default: {
            return false
        }
    }
}

export function getReconciliationComputedStatusDescription(
    intl: IntlShape,
    entity: Payment | Transaction,
    messageId?: string
) {
    const formattedObject = intl.formatMessage({ id: `api.object.${entity?.object}` }).toLowerCase()

    return intl.formatMessage(
        { id: messageId },
        {
            object: formattedObject
        }
    )
}
