import { useQueryFindByIdPaymentOrder } from '@/hooks'
import { ReconciliationsPage } from '@/pages'
import { PaymentOrder, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { useParams } from 'react-router-dom'

export const PaymentOrderReconciliationsPage: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdPaymentOrder(uuid)

    return <ReconciliationsPage<PaymentOrder> paymentType={PaymentTypeSchema.Values.payment_order} queryItem={query} />
}
