import { useParams } from 'react-router-dom'
import { useQueryFindByIdExpectedPayment } from '@/hooks'
import { ExpectedPayment, PaymentTypeSchema } from '@webapps/numeral-ui-core'
import { ReconciliationsPage } from '@/pages'

export const ExpectedPaymentsReconciliationsList: React.FC = () => {
    const { uuid } = useParams()
    const query = useQueryFindByIdExpectedPayment(uuid)

    return (
        <ReconciliationsPage<ExpectedPayment>
            paymentType={PaymentTypeSchema.Values.expected_payment}
            queryItem={query}
        />
    )
}
