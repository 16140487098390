import React, { useCallback, useMemo } from 'react'
import { FormikInput } from '@/types'
import { Flex, FormControl, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { CustomField, CustomFieldTypesSchema } from '@webapps/numeral-ui-core'
import { useField, useFormikContext } from 'formik'
import { anythingValidator } from '@/utils/@validators'
import { useIntl } from 'react-intl'
import { FormattedCustomFieldType } from '../../../FormattedCustomFieldType'

interface CustomFieldTypeInputProps extends Omit<FormikInput, 'value'> {
    value?: CustomField['type']
}

export const CustomFieldTypeInput: React.FC<CustomFieldTypeInputProps> = ({
    name,
    value,
    isRequired,
    isDisabled = false,
    validator = anythingValidator,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<typeof value>({
        name,
        value,
        validate: validator?.(intl, isRequired)
    } as any)
    const { values, setFieldValue } = useFormikContext<CustomField>()
    const isEditMode = useMemo(() => globalThis.Boolean(values?.id), [values])
    const filteredOptions = useMemo(() => {
        return CustomFieldTypesSchema.options.filter((option) => {
            switch (option) {
                case CustomFieldTypesSchema.Enum.select:
                case CustomFieldTypesSchema.Enum.text: {
                    return true
                }
                default: {
                    return false
                }
            }
        })
    }, [])
    const onChange = useCallback(
        (option: string) => {
            helpers.setValue(option as typeof value)

            // Reset `values` by `type`:
            switch (option) {
                case CustomFieldTypesSchema.Enum.select: {
                    const initialValue = { name: '', key: '' }

                    setFieldValue('values', [initialValue])

                    break
                }

                case CustomFieldTypesSchema.Enum.boolean:
                case CustomFieldTypesSchema.Enum.number:
                case CustomFieldTypesSchema.Enum.date:
                case CustomFieldTypesSchema.Enum.text:
                default: {
                    setFieldValue('values', undefined)
                    break
                }
            }
        },
        [helpers, setFieldValue]
    )

    return (
        <FormControl>
            <FormLabel htmlFor={name}>Type</FormLabel>
            <RadioGroup
                name={name}
                id={name}
                defaultValue={value}
                onChange={onChange}
                isDisabled={isEditMode || isDisabled}>
                <Stack gap="16px" marginBottom="8px">
                    {filteredOptions.map((filteredOption, index) => {
                        return (
                            <Radio value={filteredOption} key={index}>
                                <FormattedCustomFieldType customFieldType={filteredOption} key={index} />
                            </Radio>
                        )
                    })}
                </Stack>
            </RadioGroup>
        </FormControl>
    )
}
