import {
    IncomingPayment,
    IncomingPaymentReturnReasonSchema,
    IncomingPaymentSchema,
    IncomingPaymentStatusSchema,
    SepaReturnReasonSchema
} from '@webapps/numeral-ui-core'
import { isSDDPayment } from '@/services'

export function incomingPaymentReceivedSDDFilter(item?: IncomingPayment, index?: number): boolean {
    const isValidIncomingPayment = IncomingPaymentSchema.safeParse(item).success
    const isReceived = item?.status === IncomingPaymentStatusSchema.Values.received

    if (!isValidIncomingPayment) {
        return false
    }

    return isReceived && isSDDPayment(item.type, item.direction)
}

export function getIncomingPaymentReturnReasonOptions(item?: IncomingPayment) {
    switch (true) {
        case incomingPaymentReceivedSDDFilter(item): {
            return [
                SepaReturnReasonSchema.Values.MD01,
                SepaReturnReasonSchema.Values.AC01,
                SepaReturnReasonSchema.Values.AC04,
                SepaReturnReasonSchema.Values.AC06,
                SepaReturnReasonSchema.Values.AC13,
                SepaReturnReasonSchema.Values.AG01,
                SepaReturnReasonSchema.Values.AG02,
                SepaReturnReasonSchema.Values.AM04,
                SepaReturnReasonSchema.Values.BE05,
                SepaReturnReasonSchema.Values.SL01
            ]
        }

        default: {
            return IncomingPaymentReturnReasonSchema.options
        }
    }
}
